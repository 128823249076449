<template>
  <v-container fluid>
    <!-- Talous -->
    <h3 class="mb-1 mt-1">
      Kuluvan kuukauden laskutus valituilla
      {{ accountsSelected.groupSelection ? "ryhmillä" : "tileillä" }} yhteensä
    </h3>
    <v-row dense>
      <!-- Bills -->
      <v-col cols="12" sm="6" xl="4">
        <basic-card
          title="Saatavat"
          subtitle="Kuluvan kk saatavat"
          icon="file-document-outline"
          :amount="`${formatCurrency(currentMonthTotalAmount)}`"
          color="primary"
        ></basic-card>
      </v-col>
      <!-- Paid -->
      <v-col cols="12" sm="6" xl="4">
        <basic-card
          title="Suoritukset"
          subtitle="Kuluvan kk suoritukset"
          icon="currency-eur"
          :amount="`${formatCurrency(currentMonthTotalAmount - currentMonthOpenAmount)}`"
          color="success"
        ></basic-card>
      </v-col>
      <!-- waiting -->
      <v-col cols="12" sm="6" xl="4">
        <basic-card
          title="Avoinna"
          subtitle="Kuluvan kk avoimet saatavat"
          icon="alert-circle"
          :amount="`${formatCurrency(currentMonthOpenAmount)}`"
          color="warning"
        ></basic-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mixins from "@/mixins/mixins";
import axiosMethods from "@/mixins/axios";
import BasicCard from "@/components/Statistics/BasicCard";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    BasicCard,
  },

  mixins: [mixins],

  data() {
    return {
      currentMonthTotalAmount: 0,
      currentMonthOpenAmount: 0,
    };
  },

  computed: {
    ...mapState("account", ["selectedGroups", "selectedAccounts", "activeAccounts"]),

    accountsSelected() {
      if (this.getSelectedAccounts()["groupSelection"]) {
        return { amount: this.selectedGroups.length, groupSelection: true };
      } else if (
        !this.getSelectedAccounts()["groupSelection"] &&
        this.selectedAccounts.length > 0
      ) {
        return { amount: this.selectedAccounts.length, groupSelection: false };
      } else {
        return { amount: this.activeAccounts.length, groupSelection: false };
      }
    },
  },

  async created() {
    this.getStatistics();
  },

  methods: {
    ...mapGetters("account", ["getSelectedAccounts"]),

    async getStatistics() {
      try {
        this.$store.state.statistics.invoiceStatisticIsLoading = true;

        const res = await axiosMethods.post("/api/v1/statistics/invoiceData", {
          accountSelector: this.getSelectedAccounts(),
        });

        const monthlyData = res.data.monthlyInvoiceData[0];

        this.currentMonthTotalAmount =
          monthlyData.currentMonthTotalAmount.length > 0
            ? monthlyData.currentMonthTotalAmount[0].total
            : 0;
        this.currentMonthOpenAmount =
          monthlyData.currentMonthOpenAmount.length > 0
            ? monthlyData.currentMonthOpenAmount[0].total
            : 0;

        this.$store.state.statistics.invoiceStatisticIsLoading = false;
      } catch (err) {
        this.showPopup("Tilastojen haku epäonnistui", "error");
      }
    },
  },
};
</script>

<style scoped></style>
